.card-wrap{
    width: 647px;
    padding: 35px 27px 35px 35px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 15px;
}
.mine-card{
    width:258px;
    padding: 15px;
    background: #FFFFFF;
    border-radius: 8px;
}
.mine-card>div:first-child{
    padding-bottom: 17px;
}

.mine-card-container>div{
    cursor: pointer;
    width: 258px;
    height: 50px;
    left: 8px;
    top: 44px;
    border-radius: 4px;
    line-height: 50px;
    margin-bottom: 20px;
}
.mine-card-container>div:hover{
    background: #F2F2F2;
}

.mine-card-container>div>img{
    width: 38px;
    height: 38px;
    border-radius: 4px;
    object-fit: contain;
}

.mine-card-container>div>span{
   margin-left: 7px;
}


.Introduction{

}

.Introduction-left-img{
    width: 90px;
    height: 90px;
    border-radius: 4px;
    object-fit: contain;
}
.Introduction-left>div:last-child{
     margin-left: 14px;
}
.Introduction-name{
    margin-top: 4px;
}
.Introduction-num{
    margin-top: 6px;
}
.Introduction-tags{
    margin-top: 9px;
}
.Introduction-tags>div{
    cursor: pointer;
    /* width: 61px; */
    height: 28px;
    border: 1px solid #999999;
    border-radius: 58px;
    margin-right: 33px;
}

.Introduction-tags>img {
    cursor: pointer;
    width: 61px;
    height: 28px;
    margin-right: 10px;
}
.Introduction-tags>div>img{
    width: 13px;height: 13px;
    display: inline-block;
    margin-left: 9px;
}

.Introduction-tags>div>span{
     margin-right: 9px;
}
.drop-img{
    width: 36px;
    height: 23px;
    display: inline-block;
    object-fit: contain;
}
.card-switch-item{
    position: relative;
    width:33px;
    height:24px;
    margin-right:28px;
    user-select: none;
}
.card-switch{
    margin-top:25px;
    margin-bottom:25px;
}
.card-switch-item>div:first-child{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
}
.card-switch-item>div:last-child{
    position: absolute;
    bottom:2px;
    left:8px;
}
.card-switch-active{
    width: 17px;
    height: 2px;
    background: linear-gradient(91.22deg, #2A76EC 2.91%, #5DFFE9 95.46%);
    border-radius: 12px;
}
.card-item{
    padding-bottom: 19px;
    border-bottom: 1px solid #D9D9D9;
    margin-top:15px;
}
.card-item:last-child{
    border-bottom: none;
}

.card-item-top>div:first-child>img{
    width: 42px;
    height: 42px;
    display: inline-block;
    margin-right: 12px;
}
.card-item-top-time{
   margin-top:7px;
}
.card-item-top-arrow-img{
    width: 17px;height: 14px;
    display: inline-block;
}
.card-item-section{
    margin-top: 9px;
    max-width: 709px;
}

.card-item-album{
    width: 510px;
    display: grid;
    grid-template-columns: repeat(3,163px);
    gap:7px;
    margin-top: 23px;
}
.card-item-album>img{
    width:163px;
    height: 163px;
    display: inline-block;
    object-fit: contain;
}

.card-commands{
    margin-top: 29px;
}
.card-commands>div:first-child{
   padding-right:40px;
}

.card-item-nofree{
    cursor: pointer;
    width: 586px;
    height: 82px;
    background: #F2F7FF;
    border-radius: 4px;
    margin-top: 20px;
}

.card-item-nofree>div:first-child{
    margin-left: 29px;
}
.card-item-nofree>div:first-child>img{
    width:30px;
    height: 30px;
    margin-right: 7px;
}
.card-item-nofree>div:last-child{
    margin-right: 11px;
    text-align: right;
}
.card-item-nofree>div:last-child>img{
   width:5px;
   height: 9px;
   margin-left: 12.5px;
}
.leave-menu{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 30px;

    background: #FFFFFF;
    box-shadow: 2px 4px 8px rgba(165, 165, 165, 0.3);
    border-radius: 4px;
}

.leave-menu .item {
    cursor: pointer;
}

.circle-status-pending {
    color:coral;
}

.mine-card-container .current-circle {
    background-color: #F2F2F2;
}